import React from 'react';
import './Main_plan.css';

import {Helmet} from "react-helmet";

import Plan from '../Plan/Plan.js';
import MasterButton from '../../general_components/MasterButton/MasterButton.js';

import GGApi from '../../../utils/GGApi.js';
import GG_loader from '../../general_components/GG_loader/GG_loader';
import Overlay_popup from '../../general_components/Overlay_popup/Overlay_popup';

import plan_and_goal from '../../../images/illustrations/goals_and_plans.svg';

import config from '../../../utils/config';
import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);
 
class Main_plan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            open_overlay: '',
            overlay_index: 0,
            plans: [],
            child: '',
            slug: ''
            /*
            id
            user_id
            account_name
            birthday
            banner_picture

            account_id
            goal_name 
            target_savings
            target_date
            fund_id

            account_id
            fund_type
            fund_institution
            fund_number_or_api_id
            fund_amount

            */
        };

        this.renderPlans = this.renderPlans.bind(this);
        this.share_button = this.share_button.bind(this);
        this.link_button = this.link_button.bind(this);
        this.link_button_2 = this.link_button_2.bind(this);
        this.copy_to_clipboard = this.copy_to_clipboard.bind(this);
    }
    
    componentDidMount() {
        GGApi.get_plans().then(fetched_plans => {
            this.setState({plans: fetched_plans, loading: false});
        });
    }

    share_button(plan_id, link_to){
        
        let index = this.state.plans.findIndex(plan_list_item => plan_list_item.id === plan_id ? true : false)
        console.log(index);
        if (navigator.canShare){
            let user = JSON.parse(localStorage.getItem('user'));
            let child = this.state.plans[index].account_name;
            let url = ''
            let text = ''
            let title = `Invest in ${child}'s future`;


            if(link_to === "link"){
                url = `https://app.GreatestGiftApp.com/give-a-gift?Gift_for=${child}&Send_to=${user.first_name}&send_to_email=${user.email}&channel=email`;
                text = `Hey, we're saving for ${child} future! Here's a link for you to invest in ${child} future through Greatest Gift.`;
            
            } else if (link_to === 'gifting page') {
                url = this.state.plans[index].wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' + this.state.plans[index].slug 
            : 'https://app.greatestgiftapp.com/registry/' + this.state.plans[index].slug;
                
                
                text = this.state.plans[index].welcome_message;
            }
            

            navigator.share({url: url, text: text, title: title})
            .then(() => {
                amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'native' })
                alert('Successfully shared!')
            })
            .catch(err => {
                alert('There was an error when trying to share. Please try again.')
            })
        } else {
            console.log('navigator.share not available')
            if(link_to === "link"){
                this.setState({open_overlay: true, overlay_index: index, child: this.state.plans[index].account_name});
            } else if (link_to === 'gifting page') {
                this.setState({open_overlay_2: true, overlay_index: index, child: this.state.plans[index].account_name, slug: this.state.plans[index].slug, wf_item_id: this.state.plans[index].wf_item_id});
            }
        }
    }

    copy_to_clipboard(){
        /* clipboard successfully set */
        alert('The link was successfully copied!');
        this.setState({open_overlay: false, open_overlay_2: false});
    }

    link_button(){
        let user = JSON.parse(localStorage.getItem('user'));
        navigator.clipboard.writeText(`https://app.GreatestGiftApp.com/give-a-gift?Gift_for=${this.state.child}&Send_to=${user.first_name}&send_to_email=${user.email}&channel=email`)
            .then(this.copy_to_clipboard, function() {
                /* clipboard write failed */
                alert('There was an issue when attempting to copy the link.');
            })

    }


    link_button_2(){
        // amplitude
        amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'copy link' });
        navigator.clipboard.writeText(`${this.state.wf_item_id ? 'https://www.greatestgiftapp.com/gifts/' : 'https://app.greatestgiftapp.com/registry/' }${this.state.slug}`)
            .then(this.copy_to_clipboard, function() {
                /* clipboard write failed */
                alert('There was an issue when attempting to copy the link.');
            })

    }

    renderPlans(){
        return this.state.plans.map(plan => {
            return <Plan
                        key={plan.id}
                        name={plan.account_name}
                        saved_to_date={plan.total_redeemed ? plan.total_redeemed : '0'}
                        redeemed_gifts={plan.redeemed_gifts ? plan.redeemed_gifts : '0'}
                        banner_pic={plan.media_url}
                        plan_id={plan.id}
                        target_savings={plan.target_savings}
                        share_button={this.share_button}

                        page_active={plan.page_active}
                        slug={plan.slug}
                        
                        /> ;
            });
    }



    render () {
        if(this.state.loading) {
            return (
                <GG_loader />
            )
        }

        let user = JSON.parse(localStorage.getItem('user'));

        return (
            <main>
                <Helmet>
                    <title>{`Recipients & Plans | Greatest Gift`}</title>
               </Helmet>

                {this.state.plans.length !== 0 ? null : <h2>Create a plan, set goals, and track savings</h2>}
                {this.state.plans.length !== 0 ? null :
                    <div className='main_plan_illustration_container'>
                        <img className='main_plan_illustration' src={plan_and_goal}/>
                    </div>
                }
                {this.renderPlans()}
                

                <MasterButton 
                    buttonText='add a recipient account' 
                    links={true}
                    linkTo='/add_account'
                    notSticky={true}
                />
                <div className='buffer'></div>


                <Overlay_popup 
                open={this.state.open_overlay}
                closer={true}
                handleClose={() => this.setState({open_overlay: false})}
                overlay_title='Share'
                
                body_1={`Let your friends and family know you are saving for ${this.state.child}'s future and send them a link so they can start sending ${this.state.child} gifts.`}
                joker={
                    <div className='overlay_share_button_container'>
                        <a className='overlay_share_button' href={`mailto:?body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fapp.GreatestGiftApp.com%2Fgive-a-gift%3FGift_for%3D${this.state.child.split(' ').join('%2520')}%26Send_to%3D${user.first_name.split(' ').join('%2520')}%26send_to_email%3D${encodeURI(user.email)}%26channel%3Demail`}>
                            <i className='material-icons-outlined'>email</i>  
                            <span className='tiny_text'>Send email</span>
                        </a>
                        
                        <a className='overlay_share_button' href={`sms:?&body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fapp.GreatestGiftApp.com%2Fgive-a-gift%3FGift_for%3D${this.state.child.split(' ').join('%2520')}%26Send_to%3D${user.first_name.split(' ').join('%2520')}%26send_to_email%3D${encodeURI(user.email)}%26channel%3Demail`}>
                            <i className='material-icons-outlined'>textsms</i>  
                            <span className='tiny_text'>Send text</span>
                        </a>

                        <div className='overlay_share_button' 
                            onClick={this.link_button}>
                            <i className='material-icons-outlined'>link</i>  
                            <span className='tiny_text'>Copy link</span>
                        </div>

                    </div>
                }/>

                <Overlay_popup 
                open={this.state.open_overlay_2}

                closer={true}
                handleClose={() => this.setState({open_overlay_2: false})}
                overlay_title='Share Gifting Page'
                
                body_1={`Let your friends and family know you are saving for ${this.state.child}'s future.`}
                joker={
                    <div className='overlay_share_button_container'>
                        <a className='overlay_share_button' onClick={() => amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'email' })}
                            href={`mailto:?body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.slug}`}>
                            <i className='material-icons-outlined'>email</i>  
                            <span className='tiny_text'>Send email</span>
                        </a>
                        
                        <a className='overlay_share_button' onClick={() => amplitude.getInstance().logEvent('gifting page: select share channel', {'channel': 'textsms' })}
                            href={`sms:?&body=Hey%2C%20we%27re%20saving%20for%20${this.state.child}%27s%20future!%20Here%27s%20a%20link%20for%20you%20to%20invest%20in%20${this.state.child}%20future%20through%20Greatest%20Gift.%0A%0Ahttps%3A%2F%2Fwww.GreatestGiftApp.com%2Fgifts%2F${this.state.slug}`}>
                            <i className='material-icons-outlined'>textsms</i>  
                            <span className='tiny_text'>Send text</span>
                        </a>

                        <div className='overlay_share_button' 
                            onClick={this.link_button_2}>
                            <i className='material-icons-outlined'>link</i>  
                            <span className='tiny_text'>Copy link</span>
                        </div>

                    </div>
                }/>

            </main>
        );
    }
}

export default Main_plan;