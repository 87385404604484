import React from 'react';
import './Main_add_account.css';
import { Link } from 'react-router-dom';

import Tracker from '../../../layout_components/Tracker/Tracker.js';
import Add_step_1 from '../Add_step_1/Add_step_1.js';
import Add_step_2 from '../Add_step_2/Add_step_2.js';
import Add_step_3 from '../Add_step_3/Add_step_3.js';
import MasterButton from '../../../general_components/MasterButton/MasterButton.js';

import GGApi from '../../../../utils/GGApi.js';

import dates from '../../../../utils/dates.js';
import Header_for_multistep from '../../../layout_components/Header_for_multistep/Header_for_multistep';
import money from '../../../../utils/money';

import Diaper from '../../../../images/goals/diaper_fund.png'

import config from '../../../../utils/config';

import amplitude from 'amplitude-js';
import { Helmet } from 'react-helmet';
import Add_step_4 from '../Add_step_4/Add_step_4.js';
import GG_loader from '../../../general_components/GG_loader/GG_loader.js';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup.js';
import Image_cropper from '../../../general_components/Pic_selector/Image_cropper.js';
amplitude.getInstance().init(config.amp_key);

class Main_add_account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //form details
            currentStep: 1,
            buttonText: 'Next',
            buttonLinks: false,

            loading: false,

            //submission details:
                // step 1 - name and birthday
            account_name: '',
            account_name_unique: '',
            birthday: '',
            target_savings: '',
            target_date: '',
                
                // step 2 - goals, custom goal
            goals: [],
            custom_goal: {image: 'Diaper', url: Diaper},
            custom_goal_active: false,
            goal_name: '',

                // step 3 - pic, welcome msg
            media_file: '',
            local_file_url: '',
            gifting_page_welcome_message: '',
            original_media_file: '',

            user_first: '',
            user_last: '',

            open_cropper: false,

            //supporting states for masking purposes
            birthday_support:'',
            target_date_support: '',

            //results
            new_account_id: '',
            gifting_page_url: ''

        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTargetDateChange = this.handleTargetDateChange.bind(this);
        this.handleDollarInput = this.handleDollarInput.bind(this);
        this.checkUnique = this.checkUnique.bind(this);
        this.toggle_goal = this.toggle_goal.bind(this);
        this.handlePicChange = this.handlePicChange.bind(this);
    }

    // button clicks
    handleButtonClick() {
        let step = this.state.currentStep;        
        
        if (step === 1) {
            //validate that a name has been inputted
            if(!this.state.account_name){
                alert('Please submit a valid recipient name, up to 50 characters.')
            } else if (!this.state.account_name_unique) {
                alert('You already have a recipient with this name. Please Choose a different name for this recipient account.');
            } else {

                // validate the birthday date
                    // check for a full number of characters
                if (!(this.state.birthday.length === 10)) {
                    alert('Please input a valid birthday in the following format: \nMM/DD/YYYY');
                    //check that the input is a valid date between the years 1900-2200
                } else if (!dates.validateDate(this.state.birthday)) {
                    alert('Please input a valid birthday in the following format: \nMM/DD/YYYY');
                } else {
                    // amplitude
                    amplitude.getInstance().logEvent('new recipient: submit personal info')
                    //
                    step += 1;
                    window.scrollTo(0, 0);
                    this.setState({currentStep: step, buttonText: 'Next'});
                }
            }
        } else if (step === 2) {
            
            ///////

            // validate goals - at least one is chosen?
            let goals = this.state.goals;
            const validGoals = ['college fund','future savings','diaper fund','puppy fund','house fund','first car fund',
                'adulting fund','emergency fund','financial independence','disney trip fund','crypto fund','wedding fund','vacation fund', 'adventure fund'];
            const isSubset = (chosenGoals, validGoals) => {
                return chosenGoals.every(goal => validGoals.includes(goal));
            };
            if(!isSubset(goals, validGoals)){
                alert('Please select goals from the presented options');
            } else {

                // validate custom goal
                if(this.state.custom_goal_active){
                    // needs to have a name
                    if(!this.state.goal_name){
                        alert('Please name your custom goal');
                    } else {
                        // image needs to be from preset
                        const validCustomGoalImg = ['Financial','Future','Emergency','Diaper','College','Crypto','Vacation']
                        let custom_goal_img = this.state.custom_goal.image;
                        if(!validCustomGoalImg.includes(custom_goal_img)){
                            alert('Please choose a valid goal image');
                        } else {
                            amplitude.getInstance().logEvent('new recipient: submit goal')
                            step += 1;
                            window.scrollTo(0, 0);
                            this.setState({currentStep: step, buttonText: 'Finish'});        
                        }
                    }


                } else { // no custom goal, can move forward
                    amplitude.getInstance().logEvent('new recipient: submit goal')
                    step += 1;
                    window.scrollTo(0, 0);
                    this.setState({currentStep: step, buttonText: 'Finish'});
                }
                
            }
                ///////
                
            /*
            // validate target savings
            let target_savings = Number(this.state.target_savings.slice(1).split(',').join(''));
            if (isNaN(target_savings )) {
                alert('Please input a valid target savings $ amount in whole dollars');
                
            } else if (target_savings < 1 ){
                alert('Please input a valid target savings $ amount in whole dollars');
                
                // validate the target date
            } else {
                // check for a full number of characters
            if (!(this.state.target_date.length === 10) || !dates.validateDate(this.state.target_date) || !dates.checkFutureDate(this.state.target_date)) {
                alert('Please input a valid future target date in the following format: \nMM/DD/YYYY');
            } else {
                // amplitude
            amplitude.getInstance().logEvent('new recipient: submit goal')
            //
            step += 1;
        }
        */
        } else if (step === 3) {
            // validations for step 3
                // validate the welcome message
                //if(!this.state.gifting_page_welcome_message){
                  //  alert('Please choose or write a welcome message')
                //} else {
                    // amplitude
                    amplitude.getInstance().logEvent('new recipient: personalized gifting page')
                    //

                    // start loader
                    this.setState({loading: true})
                    // if pic uploaded, upload it now

                    ////////

                    // create the new recipient account, with gifting page
                    let newAccount = {
                        account_name: this.state.account_name.trimEnd(),
                        birthday: dates.sqlizeDate(this.state.birthday),
                        //target_savings: Number(this.state.target_savings.slice(1).split(',').join('')),
                        //target_date: dates.sqlizeDate(this.state.target_date)
                        goals: this.state.goals,
                        
                        custom_goal: this.state.custom_goal,
                        custom_goal_active: this.state.custom_goal_active,
                        goal_name: this.state.goal_name,

                        gifting_page_welcome_message: this.state.gifting_page_welcome_message ? this.state.gifting_page_welcome_message : `${this.state.account_name} is saving for a bright financial future!`
                    };

                    if(this.state.media_file){
                        GGApi.upload_media(this.state.media_file, 'gifting-page-media').then(response => {
                            // handle media upload error
                            let media;
                            if(response.errors){
                                alert(response.errors[0].detail + ' \nThere was an issue when attempting to upload the image. Please try again.')
                            } else if(response.mediaUrl){
                                media = response.mediaUrl;
                            };
                            GGApi.add_account_w_page(newAccount, media).then(result => {
                                if(!result.success){
                                    this.setState({loading: false})
                                    alert('There was an issue when trying to create the recipient. Please try again, or contact our support team for further assistance.')
                                } else if (result.success) {

                                    // move to final flow screen
                                    step += 1;
                                    window.scrollTo(0, 0);
                                    this.setState(
                                        {
                                            currentStep: step, 
                                            loading: false,
                                            new_account_id: result.new_account_id,
                                            gifting_page_url: result.gifting_page_url
                                        });
                                }
                            })
                        })
                    } else {
                        GGApi.add_account_w_page(newAccount, '').then(result => {
                            if(!result.success){
                                this.setState({loading: false})
                                alert('There was an issue when trying to create the recipient. Please try again, or contact our support team for further assistance.')
                            } else if (result.success) {

                                // move to final flow screen
                                step += 1;
                                window.scrollTo(0, 0);
                                this.setState(
                                    {
                                        currentStep: step, 
                                        loading: false,
                                        new_account_id: result.new_account_id,
                                        gifting_page_url: result.gifting_page_url
                                    });
                            }
                        })
                    }
                //} 
        } else if (step === 4) {
            // this is outdated, the button is hidden in step 4, instead see link in render

            /* reset the state for the next flow 
            this.setState(
                {
                currentStep: 1,
                buttonText: 'Next'
                });
                */
            amplitude.getInstance().logEvent('new recipient: finish')
            // go back to the last screen before this flow
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else {
                window.location.href = 'plan'
            }
            //this.props.history.goBack();

        } 
    }


    handleBackButton() {
        let step = this.state.currentStep;
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        if (step === 1) {
            //this.props.history.goBack()
            // go back to the last screen before this flow
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else {
                window.location.href = 'plan'
            }

        } else if (step === 2) {
            step -= 1;
            this.setState({currentStep: step});

        } else if (step === 3) {
            step -= 1;
            this.setState({currentStep: step, buttonText: 'Next'});

        } else if (step === 4) {
            //this.props.history.goBack();
            // go back to the last screen before this flow
            if(urlParams.get('source')){
                window.location.href = 'receive/'+urlParams.get('source');
            } else {
                window.location.href = 'plan'
            }
        };

    }

    // input event handlers
    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
    }

    checkUnique(event, type) {
        if (type === 'account_name') {
            GGApi.unique_child_account(event.target.value.toLowerCase()).then(unique => {
                if(!unique) {
                    alert('You already have a recipient with this name. Please Choose a different name for this recipient account.');
                };
                this.setState({account_name_unique: unique});
            });
        } 
    }

    handleDateChange(event) {
        console.log(event);
        if(event){
            this.setState({birthday: event, birthday_support: event});
        }
        
        /*
        const { name, value} = event.target;
        this.setState({[name]: value, birthday_support: value});

        if (event.target.value.length === 2 && this.state.birthday_support.length === 1) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 5 && this.state.birthday_support.length === 4) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        };  
        */
    }

    handleTargetDateChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, target_date_support: value});

        if (event.target.value.length === 2 && this.state.target_date_support.length === 1) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 5 && this.state.target_date_support.length === 4) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        };  
    }


    handleDollarInput(event) {
        /* Need to add validation here */
        const { name, value} = event.target;

        // get number without $
        let number = value;
        if(number[0] === '$'){
            number = number.slice(1);
        }

        // check for commas
        // if commas are bad - strip commas, and add new ones
        if(!money.checkForCommas(number)){
            number = money.addCommas( money.removeCommas(number) )
        }

        let $value = '$' + number;
        this.setState({[name]: $value});
    }

    // goals toggle
    toggle_goal(goal){
        let goals = this.state.goals;
        let new_goals;
        let lower_case_goal = goal.toLocaleLowerCase();
        
        if(goals.includes(goal.toLocaleLowerCase())){
            let index = goals.findIndex( item => 
            item === lower_case_goal
            );
            new_goals = goals.slice(0, index).concat(goals.slice(index + 1));
            this.setState({goals: new_goals})
        } else {
            goals.push(goal.toLocaleLowerCase());
            this.setState({goals: goals})
        }
    }

    // pic handler
    handlePicChange(event) {
        let file = event.target.files[0];
        // try to convert here to jpeg?
        if(!file){
            return;
        }
        if(file.size > 4194304) {
            alert('Please select a photo with a max size of 4MBs.');
            return;
        }
        
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({media_file: event.target.files[0],  local_file_url: reader.result, open_cropper: true });
        };
        reader.readAsDataURL(file);  // This converts the file to a base64 string

        this.setState(
            {media_file: event.target.files[0], open_cropper: true}
        );
    }

    // component loading 
    componentDidMount() {
        //this.props.removeNavBar();
        // amplitude
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('source')){
            amplitude.getInstance().logEvent('new recipient: start new recipient', {'last page': 'received gift'})
        } else {
            amplitude.getInstance().logEvent('new recipient: start new recipient', {'last page': 'plans / home'})
        }
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({user_first: user.first_name, user_last: user.last_name})
        
    }
    
    render () {

        const urlParams = new URLSearchParams(window.location.search)

        if(this.state.loading) {
            return (
                <GG_loader/>
            )
        } else {
            return (
            <div className='home_background'>
                <Header_for_multistep 
                    text='Add Recipient'
                    handleBackButton={() => this.handleBackButton()}
                />
    
                <Helmet>
                    <title>{`Add Recipient | Greatest Gift`}</title>
                    <meta name="description"
                    content='Add a new gift recipient to your account' 
                    />
                </Helmet>
    
                <main>
                    {this.state.currentStep === 4 ? null :
                    <Tracker 
                        currentStep={this.state.currentStep} numOfSteps={3}
                        tags={['Name & Age', 'Goals', 'Personalize']}/>
                    }
                    <Add_step_1 currentStep={this.state.currentStep} 
                                    handleChange={this.handleChange}
                                    handleDateChange={this.handleDateChange}
                                    account_name={this.state.account_name}
                                    birthday={this.state.birthday}
                                    checkUnique={this.checkUnique}
                                    />
    
                    <Add_step_2 currentStep={this.state.currentStep} 
                                    handleChange={this.handleChange}
                                    handleTargetDateChange={this.handleTargetDateChange}
                                    account_name={this.state.account_name.trimEnd()}
                                    target_savings={this.state.target_savings}
                                    target_date={this.state.target_date}
                                    handleDollarInput={this.handleDollarInput}
                                    birthday={this.state.birthday}
    
                                    toggle_goal={this.toggle_goal}
                                    goals={this.state.goals}
                                    custom_goal_active={this.state.custom_goal_active}
                                    active_goal_false={() => this.setState({custom_goal_active: false})}
                                    active_custom={() => this.setState({custom_goal_active: true})}
                                    custom_goal={this.state.custom_goal}
                                    changeGoalImg={(goal) => this.setState({custom_goal: goal})}
                                    goal_name={this.state.goal_name}
    
    
                                    />
    
                    <Add_step_3 
                        currentStep={this.state.currentStep} 
                        handleChange={this.handleChange}
                        account_name={this.state.account_name.trimEnd()}
                        
                        handlePicChange={this.handlePicChange}
                        media_file={this.state.media_file}
                        original_media_file={this.state.original_media_file}
    
                        gifting_page_welcome_message={this.state.gifting_page_welcome_message}
                        select_item={(selected => {this.setState({gifting_page_welcome_message: selected})})}
    
                        user_first={this.state.user_first}
                        user_last={this.state.user_last}
                        />

                    <Overlay_popup 
                        open            = {this.state.open_cropper}
                        closer          = {true}
                        handleClose     = {() => this.setState({open_cropper: false})}

                        overlay_title   = 'Edit your picture'
                        wide            = {true}
                        joker           = {
                        
                        <div>
                        <Image_cropper 
                        imageSrc={this.state.local_file_url ? this.state.local_file_url : this.state.original_media_file}
                        onCropComplete={(croppedImage) => {
                                console.log('crop complete', croppedImage)
                                this.setState({media_file: croppedImage, open_cropper: false})
                            }}
                        />
                        </div>}
                    />
    
                    <Add_step_4 
                        currentStep={this.state.currentStep} 
                        handleChange={this.handleChange}
                        account_name={this.state.account_name.trimEnd()}
                        new_account_id={this.state.new_account_id}
                        gifting_page_url={this.state.gifting_page_url}
                        updateActiveRecipient={this.props.updateActiveRecipient}
                        source={urlParams.get('source') ? urlParams.get('source') : null}
                    />
    
    
                
                { this.state.currentStep === 4 ?
                <Link className='secondary_dark align_center' 
                    to={ urlParams.get('source') ? 'receive/'+urlParams.get('source') : 'plan'}
                    onClick={() => amplitude.getInstance().logEvent('new recipient: finish')}>
                    <p>Finish</p>
                </Link>
    
                :
    
                <MasterButton 
                    notSticky={true}
                    buttonText={this.state.buttonText} 
                    handleButtonClick={this.handleButtonClick}
                    links={this.state.buttonLinks}
                />
                }
    
                </main>
            
                <div className='buffer'></div>
                <div className='buffer'></div>
                <div className='buffer'></div>
                <div className='buffer'></div>
    
            </div>
            )
        }
    }
}

export default Main_add_account;